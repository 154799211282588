import {flatten, get, isEqual, isObject, transform, truncate} from "lodash";
import {JURISDICTION_MAP, CORPORATE_TOOL_MAPPING} from "./constants";
import axios from "../../src/axios/index.js";
import rawAxios from "axios";
import store from "@/store";
import {text} from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import services from "../views/research/tools/whatsapp/services.js";

const options = {
    // You can set your default options here
};
Vue.use(Toast, options);

import {APP_CONFIG} from "@/utils/constants";

import {DateTime} from "luxon";
import {fas} from "@fortawesome/free-solid-svg-icons";

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function getUniqueList(list) {
    return list.filter(onlyUnique);
}

export function FormatDateNew(date) {
    const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
    const month = date.toLocaleString("default", {month: "short"});
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

export function getJurisdictionName(code) {
    code = code.toLowerCase() === "uk" ? "gb" : code.toLowerCase(); // Change uk jurisdiction code to gb
    return JURISDICTION_MAP.find((j) => j.jurisdiction.code == code)?.jurisdiction?.full_name;
}

export function showToast(error) {
    if (error?.response?.status === 500) {
        Vue.$toast.error("Internal Server Error");
    } else if (error?.response?.status === 401) {
        Vue.$toast.warning("Unauthorised");
    } else if (error?.response?.data?.detail) {
        if (Array.isArray(error?.response?.data?.detail)) {
            Vue.$toast.error(error?.response?.data?.detail[0]?.msg);
        } else {
            Vue.$toast.error(error?.response?.data?.detail.message);
        }
    }
}

export function convertToCSV(arr) {
    let data = JSON.parse(JSON.stringify(arr));
    let keys = [];
    if (data && data.length) {
        keys = Object.keys(data[0]);
        for (let i = 0; i < data.length; i++) {
            let row = data[i];
            for (let j = 0; j < keys.length; j++) {
                let column = keys[j];
                if (typeof row[column] === "object" && row[column]) {
                    row[column] = parseCSV(row[column], column);
                }
            }
        }
        return data;
    } else {
        // Vue.$toast.error("Error: No data to download !");
        return [];
    }
}

export function parseCSV(data, key) {
    return JSON.parse(
        JSON.stringify(
            Object.keys(data)
                .map(function(k) {
                    if (typeof data[k] === "object" && data[k]) return `${key}.${k} : ${parseCSV(data[k], `${key}.${k}`)}`;
                    else return `${key}.${k} : ${data[k]}`;
                })
                .join(";")
        )
    );
}

export function csvFileName(tool, query, section) {
    let date = moment(new Date()).format("YYMMDD");
    if (query) return `${tool.replace(" ", "").toLocaleLowerCase()}_${query}_${section.replace(" ", "")}_${date.replace("-", "")}.csv`;
    else return `${tool.replace(/' '/g, "").toLocaleLowerCase()}_${section.replace(/' '/g, "")}_${date}.csv`;
}

export function getCardDims(entity) {
    const maxWidth = Math.ceil(
        Math.max(
            ...["type", "value"].map((item) => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");
                ctx.font = "14px var(--brand-font)";
                return ctx.measureText(
                    truncate(entity[item], {
                        length: 20,
                        omission: "...",
                    })
                ).width;
            })
        )
    );

    const padding = 70;
    if (!entity.picture && !entity.isActive) return {width: 47, height: 47};
    if (entity.picture) return {width: 130, height: 130};
    else
        return {
            width: maxWidth + padding,
            height: 50,
        };
}

export function getSocialProfileIcon(platform) {
    let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == platform);
    if (currentPlatform.length > 0) {
        return currentPlatform[0].icon_data;
    }
}

export function getIcon(key) {
    let currentPlatform = this.$store.getters.getIconsData.filter((elem) => elem.key == key);
    if (currentPlatform.length > 0) {
        return currentPlatform[0].icon_data;
    }
}

export function getPlatformName(key) {
    let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == key);
    if (currentPlatform.length > 0) return currentPlatform[0].name;
    else return key;
}

export function getPlatformURL(key, username) {
    let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == key);
    if (currentPlatform.length > 0) return currentPlatform[0].profile_url.replace("%s", username);
}

export function getPlatformRedirectURL(key, username, nsid) {
    let currentPlatform = this.$store.getters.getSocialPlatforms.filter((elem) => elem.key == key);
    if (currentPlatform.length > 0) {
        if (key === "flickr" && nsid) return currentPlatform[0].profile_url.replace("%s", nsid);
        else return currentPlatform[0].profile_url.replace("%s", username);
    }
}

export function getCircularAngles(points) {
    return [...Array(points).keys()].map((num) => num * ((Math.PI * 2) / points));
}

export function getCoorByAngle(origin, angle, radius) {
    return {
        x: origin.x + Math.sin(angle) * radius,
        y: origin.y + Math.cos(angle) * radius,
    };
}

export function getCircularCoords(origin, points, radius) {
    return getCircularAngles(points).map((angle) => getCoorByAngle(origin, angle, radius));
}

export function cartesianToPolar(x, y) {
    const distance = Math.sqrt(x * x + y * y);
    const radians = Math.atan2(y, x);
    const polarCoor = {
        distance: distance,
        radians: radians,
    };
    return polarCoor;
}

export function radToDegree(radians) {
    return radians * (180 / Math.PI);
}

export function degToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

export function parseData(data) {
    return JSON.parse(JSON.stringify(data));
}

export function prettyPrint(object) {
    return JSON.stringify(object, undefined, 2);
}

export function prettyPrintDev(object) {}

export function collisionDetection(c1, c2) {
    const dx = c1.cx - c2.cx;
    const dy = c1.cy - c2.cy;
    const sumrad = c1.radius + c2.radius;

    return Math.pow(dx, 2) + Math.pow(dy, 2) <= Math.pow(sumrad, 2) ? true : false;
}

export function distance(x1, y1, x2, y2) {
    const dx = x2 - x1;
    const dy = y2 - y1;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}

export function randPosBound(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function angleBetweenLines(
    L1,
    L2,
    options = {
        flip: false,
        inDegrees: false,
    }
) {
    const dL1x = L1.x2 - L1.x1;
    const dL1y = L1.y2 - L1.y1;

    const dL2x = L2.x2 - L2.x1;
    const dL2y = L2.y2 - L2.y1;

    let angle = Math.atan2(dL1x * dL2y - dL1y * dL2x, dL1x * dL2x + dL1y * dL2y);

    if (angle < 0 && options.flip) {
        angle = angle * -1;
    }

    return options.inDegrees ? angle * (180 / Math.PI) : angle;
}

export function coorIntersection(L1, L2) {
    var sepa,
        sepb,
        den = (L2.y2 - L2.y1) * (L1.x2 - L1.x1) - (L2.x2 - L2.x1) * (L1.y2 - L1.y1);
    if (den == 0) {
        return null;
    }
    sepa = ((L2.x2 - L2.x1) * (L1.y1 - L2.y1) - (L2.y2 - L2.y1) * (L1.x1 - L2.x1)) / den;
    sepb = ((L1.x2 - L1.x1) * (L1.y1 - L2.y1) - (L1.y2 - L1.y1) * (L1.x1 - L2.x1)) / den;
    return {
        side: L1.path,
        x: L1.x1 + sepa * (L1.x2 - L1.x1),
        y: L1.y1 + sepa * (L1.y2 - L1.y1),
        div1: sepa >= 0 && sepa <= 1,
        div2: sepb >= 0 && sepb <= 1,
    };
}

export function getBoundingCoords(origin, dimensions) {
    const {x, y} = origin;
    const {width, height} = dimensions;

    const tl = origin;
    const tr = {
        x: x + width,
        y: y,
    };
    const br = {
        x: x + width,
        y: y + height,
    };
    const bl = {
        x: x,
        y: y + height,
    };

    return {tl, tr, br, bl};
}

export function isIntersecting(r1, r2) {
    return !(r2.x > r1.x + r1.width || r2.x + (r2.width || 0) < r1.x || r2.y > r1.y + r1.height || r2.y + (r2.height || 0) < r1.y);
}

export function generateRandomString(uuid = true) {
    if (uuid) {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    } else {
        return Math.random()
            .toString(32)
            .substring(2)
            .split("")
            .map((char, index) => {
                const bool = Math.round(Math.random());
                return bool == 1 ? (index % 2 === 0 ? char.toLocaleUpperCase() : char.toLocaleLowerCase()) : index % 2 === 0 ? char.toLocaleLowerCase() : char.toLocaleUpperCase();
            })
            .reduce((cur, pre) => `${cur}${pre}`);
    }
}

export function absToRelCoords(from, to) {
    const quadrant = getRelativeQuadrant(from, to);
    return {
        x: quadrant === 1 || quadrant === 4 ? to.cx + from.cx : quadrant === 2 || quadrant === 3 ? to.cx - from.cx : 0,
        y: quadrant === 1 || quadrant === 2 ? to.cy - from.cy : quadrant === 3 || quadrant === 4 ? to.cy + from.cy : 0,
    };
}

export function relToAbsCoords(from, to) {
    const quadrant = getRelativeQuadrant(from, to);
    return {
        x: quadrant === 1 || quadrant === 4 ? to.cx + from.cx : quadrant === 2 || quadrant === 3 ? to.cx - from.cx : 0,
        y: quadrant === 1 || quadrant === 2 ? to.cy - from.cy : quadrant === 3 || quadrant === 4 ? to.cy + from.cy : 0,
    };
}

export function castObject(object, keyMap) {
    const res = Object.entries(keyMap).reduce(
        (map, [key, value]) => ({
            ...map,
            [value]: object[key],
        }),
        {}
    );

    return res;
}

export function getRelativeQuadrant(from, to) {
    return from.cx < to.cx && to.cy < from.cy ? 1 : to.cx < from.cx && to.cy < from.cy ? 2 : to.cx < from.cx && from.cy < to.cy ? 3 : from.cx < to.cx && from.cy < to.cy ? 4 : 0;
}

export function rgbToHex(rgb) {
    const [r, g, b] = rgb
        .replace(/[a-z()]/g, "")
        .split(",")
        .map((val) => +val);

    if (rgb.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) return rgb;
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function deepCompare(object, base) {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}

export function dashedCase(string) {
    return !string ? "" : string.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
}

export function camelToSnake(string) {
    return !string ? "" : string.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
}

export function checkAlignment(r1, r2, padding = 5) {
    const maxWidth = Math.max.apply(
        Math,
        [r1, r2].map(function(r) {
            return r.width;
        })
    );
    const mxWItem = [r1, r2].find((item) => item.width === maxWidth);
    const align = mxWItem.x - padding < r1.x + r1.width * 0.5 && r2.x + r2.width * 0.5 && mxWItem.x + padding + maxWidth > r1.x + r1.width * 0.5 && r2.x + r2.width * 0.5 ? "V" : "H";

    return align;
}

export function midPoint(p1, p2) {
    return {x: (p1.x + p2.x) * 0.5, y: (p1.y + p2.y) * 0.5};
}

export function getRectsBetween(r1, r2, normalize = false) {
    let vertices = Array(16);

    const r1p = {x: r1.x, y: r1.y};
    const r2p = {x: r2.x, y: r2.y};

    vertices = [
        {x: r1p.x, y: r2p.y},
        {x: r1p.x + r1.width, y: r2p.y},
        {x: r2p.x, y: r2p.y},
        {x: r2p.x + r2.width, y: r2p.y},

        {x: r1p.x, y: r2p.y + r1.height},
        {x: r1p.x + r1.width, y: r2p.y + r1.height},
        {x: r2p.x, y: r2p.y + r2.height},
        {x: r2p.x + r2.width, y: r2p.y + r2.height},

        {x: r1p.x, y: r1p.y},
        {x: r1p.x + r1.width, y: r1p.y},
        {x: r2p.x, y: r1p.y},
        {x: r2p.x + r2.width, y: r1p.y},

        {x: r1p.x, y: r1p.y + r1.height},
        {x: r1p.x + r1.width, y: r1p.y + r1.height},
        {x: r2p.x, y: r1p.y + r1.height},
        {x: r2p.x + r2.width, y: r1p.y + r1.height},
    ];

    if (normalize) {
        vertices[4].y = vertices[6].y;
        vertices[5].y = vertices[7].y;
    }

    const centeroids = [
        coorIntersection(
            {
                x1: vertices[5].x,
                y1: vertices[5].y,
                x2: vertices[10].x,
                y2: vertices[10].y,
            },
            {
                x1: vertices[6].x,
                y1: vertices[6].y,
                x2: vertices[9].x,
                y2: vertices[9].y,
            }
        ),
        coorIntersection(
            {
                x1: vertices[1].x,
                y1: vertices[1].y,
                x2: vertices[6].x,
                y2: vertices[6].y,
            },
            {
                x1: vertices[2].x,
                y1: vertices[2].y,
                x2: vertices[5].x,
                y2: vertices[5].y,
            }
        ),
        coorIntersection(
            {
                x1: vertices[6].x,
                y1: vertices[6].y,
                x2: vertices[11].x,
                y2: vertices[11].y,
            },
            {
                x1: vertices[7].x,
                y1: vertices[7].y,
                x2: vertices[10].x,
                y2: vertices[10].y,
            }
        ),
        coorIntersection(
            {
                x1: vertices[9].x,
                y1: vertices[9].y,
                x2: vertices[14].x,
                y2: vertices[14].y,
            },
            {
                x1: vertices[10].x,
                y1: vertices[10].y,
                x2: vertices[13].x,
                y2: vertices[13].y,
            }
        ),
        coorIntersection(
            {
                x1: vertices[4].x,
                y1: vertices[4].y,
                x2: vertices[9].x,
                y2: vertices[9].y,
            },
            {
                x1: vertices[5].x,
                y1: vertices[5].y,
                x2: vertices[8].x,
                y2: vertices[8].y,
            }
        ),
    ];

    return {
        vertices: flatten(vertices),
        centeroids,
    };
}

export function roundProps(obj) {
    for (const key in obj) {
        if (typeof obj[key] === "number") obj[key] = Math.ceil(obj[key]);
    }
    return obj;
}

export function quadChange(quad, val, type) {
    return type === "x" ? (quad === 1 || quad === 4 ? val : quad === 2 || quad === 3 ? -val : 0) : type === "y" ? (quad === 1 || quad === 2 ? -val : quad === 3 || quad === 4 ? val : 0) : "";
}

export function getCenter({x, y, width, height}) {
    return {x: x + width * 0.5, y: y + height * 0.5};
}

export function filterDropdownData(payload) {
    let data = [];
    let platform = payload.platform;
    let input_type = payload.input_type;
    let dataItem = null;
    payload.values.forEach((item) => {
        dataItem = data.find((di) => {
            return di.value === item.value && di.val_type === item.val_type;
        });
        if (dataItem) {
            if (item.platform === "linkedin" && item.val_type === "email") dataItem["visitedlinkedin"] = true;
            if (item.platform === "linkedin-discover" && item.val_type === "email") dataItem["visitedlinkedinDiscover"] = true;
            if (item.platform === platform && (item.visited || item.visited === false)) dataItem["visited"] = item.visited;
        } else {
            if (item.value) {
                item.name = item.value;
                item.input_type = input_type;
                if (item.platform === "linkedin" && item.val_type === "email") item.visitedlinkedin = true;
                if (item.platform === "linkedin-discover" && item.val_type === "email") item.visitedlinkedinDiscover = true;
                data.push(item);
                if (item.platform !== platform && item.value !== "divider") data[data.length - 1].visited = false;
            }
        }
    });

    // sort data based on visited flag
    data.sort(function(a, b) {
        if (a.visited) return 1;
        else return -1;
    });
    return data;
}

export async function setUsernamesDropdownData(url, data) {
    const response = await axios.post(url, data);
    try {
        if (response && response.data && response.data) {
            return "success";
        }
    } catch (error) {
        let message = error.message;
    }
}

export function breakText(texts, charWidth, boxWidth) {
    texts = texts.split(" ");
    let newTexts = [];

    for (const wd of texts) {
        if (wd.includes("\n")) {
            let splitedWordList = wd.split("\n");
            splitedWordList.forEach((splitedWord, index) => {
                newTexts.push(splitedWord);
                if (index !== splitedWordList.length - 1) {
                    newTexts.push("\n");
                }
            });
        } else {
            newTexts.push(wd);
        }
    }
    texts = newTexts;

    let bt = [];
    let ct = "";

    for (let tx of texts) {
        if (tx === "\n") {
            if (ct) {
                bt.push(ct);
                ct = "";
            }
            continue;
        }

        let txl = getWordWidth(tx, charWidth);
        let truecon = "";
        if (txl > boxWidth) {
            truecon = "tcl >";

            if (ct) {
                bt.push(ct);
                ct = "";
            }

            let wl = breakWord(tx, charWidth, boxWidth);
            bt = [...bt, ...wl.splice(0, wl.length - 1)];
            ct = ct + wl[wl.length - 1];
        } else if (getWordWidth(ct, charWidth) + getWordWidth(tx, charWidth) + charWidth > boxWidth) {
            truecon = "ct + tx >";
            if (ct) {
                bt.push(ct);
                ct = "";
            }
            ct = tx;
        } else {
            if (ct) {
                tx = " " + tx;
            }
            ct = ct + tx;
        }
    }
    if (ct) {
        bt.push(ct);
    }

    return bt;
}

function breakWord(text, charWidth, width) {
    let wl = [];
    let ct = "";
    for (const t of text) {
        ct = ct + t;
        if (getWordWidth(ct, charWidth) > width) {
            wl.push(ct.slice(0, ct.length - 1));
            ct = ct[ct.length - 1];
        }
    }
    if (ct) {
        wl.push(ct);
    }
    return wl;
}

export function getWordWidth(word, smallCharWidth) {
    let width = 0;
    for (const char of word) {
        if (is_number(char)) width = width + smallCharWidth * 1.2;
        else if (char === char.toUpperCase()) width = width + smallCharWidth * 1.6;
        else width = width + smallCharWidth;
        if (["m", "w"].includes(char.toLocaleLowerCase())) width = width + smallCharWidth;
    }
    return width;
}

function is_number(char) {
    {
        return !isNaN(parseInt(char));
    }
}

/*
This method fetched a new token from the auth server(iam),
when the token has been expired.

@param {string} token - expired access token.
@returns {string} - Access token
  */
export async function fetchRefreshToken(token) {
    const url = `${process.env.VUE_APP_USER_API_URL}/api/v1/refresh-token?token=${token}`;
    try {
        let responce = await rawAxios.get(url);
        return responce.data.access_token;
    } catch (error) {
        console.error(error);
        throw new Error("Refresh token failed.");
    }
}

export async function fetchWlpRefreshToken(token) {
    const url = `${process.env.VUE_APP_USER_API_URL}/api/v1/refresh-external-access-token?token=${token}`;
    try {
        let responce = await rawAxios.get(url);
        return responce.data.access_token;
    } catch (error) {
        console.error(error);
        throw new Error("Refresh token failed.");
    }
}

async function validateToken(payload) {
    let token = await validateTokenRemote(payload);
    if (token) {
        let data = await validateTokenLocal({token});
        return data;
    } else {
        return false;
    }
}

async function validateTokenRemote(payload) {
    let {token} = payload;
    try {
        let response = await axios.post("/authorization/validate-token", {token});
        if (response.data) {
            return response.data.token;
        } else {
            // If server was not able to validate or refresh token, logout!
            return false;
        }
    } catch {
        return false;
    }
}

async function validateTokenLocal(payload) {
    let {token} = payload;
    let decodedToken;
    try {
        decodedToken = JWT.verify(token, SSO_PUBLIC_CERT, {ignoreExpiration: true});
        const current_time = Math.floor(DateTime.now().toUTC().ts / 1000);
        const expiry_time = parseInt(decodedToken.exp);
        const expirationTime = expiry_time - current_time;
        if (expirationTime <= 0) {
            // Hit expiration, try fetching refresh token from server
            token = await validateTokenRemote({token});
            if (!token) {
                return false;
            }
        }
        return {
            loggedIn: true,
            decodedToken,
            token,
            expirationTime,
        };
    } catch (error) {
        // Local token is invalid...
        return false;
    }
}

async function logoutFromSaas(token) {
    const url = `/authorization/logout`;
    try {
        const resp = await axios.post(url, {token: token});
        return resp.data.success;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export function setHeaders(config) {
    config.headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
    config.headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
    config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
    return config;
}

export {validateTokenLocal, validateTokenRemote, validateToken, logoutFromSaas};

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function getCountryName(code) {
    if (code?.length > 2) {
        let country_name = JURISDICTION_MAP.find((ele) => ele.jurisdiction.code == code.toLowerCase() && !ele.flag_code)?.jurisdiction?.full_name;
        if (country_name) {
            let flag_code = JURISDICTION_MAP.find((ele) => ele.jurisdiction.full_name == country_name && ele.jurisdiction.code !== code.toLowerCase());
            if (flag_code?.jurisdiction?.code) {
                code = flag_code?.jurisdiction?.code.toLowerCase();
            }
        }
    }
    return JURISDICTION_MAP.find((j) => j.jurisdiction.code == code)?.jurisdiction?.full_name;
}

export function getMappingValue(data_values = [], source, mapValues, tag) {
    const result_dict = data_values.map((ele) => {
        let list = Object.entries(mapValues);
        const result = list.reduce((accumulator, item) => {
            let keys = item[1][source].keys;
            let separator = item[1][source].separator;
            accumulator[item[0]] = keys
                .map((key) => {
                    //Iterating inside array for fetching the values in "iterable_key"
                    if (item[1][source].iterable_key) {
                        return get(ele, key, []).map((el1) => get(el1, item[1][source].iterable_key, ""));
                    }
                    if (typeof item[1][source] === "object") {
                        return get(ele, key, []);
                    }
                    return get(ele, key, "N/A");
                })
                .filter((e) => e != null && e != "")
                .join(separator ? separator : ", ");
            return accumulator;
        }, {});
        result.key = source;
        result.sourceTag = tag;
        return {...result, api_all_data: ele};
    });
    return result_dict;
}

export async function getConfig(key) {
    let promise = new Promise((resolve, _reject) => {
        axios.post("/config", {key}).then((configResponse) => {
            resolve(configResponse.data.config);
        });
    });
    let result = await promise;
    return result;
}

export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
//// Dummy data
// field_name:[
//     {
//         "status": "updated",
//         "original_value": flat_old_doc[field],
//         "changed_value": flat_new_doc[field],
//         "timestamp": datetime.utcnow().strftime("%Y-%m-%dT%H:%M:%S.%fZ"),
//         "id": change_id
//     },

//     {
//         "status": "deleted",
//         "original_value": flat_old_doc[field],
//         "changed_value": flat_new_doc[field],
//         "timestamp": datetime.utcnow().strftime("%Y-%m-%dT%H:%M:%S.%fZ"),
//         "id": change_id
//     }
// ]
/// monitoring function
export function getMonitoring(field_name, og_value, noHtml) {
    if (this.$route.name === "tools") return og_value;
    else {
        let result = "";
        let html = {
            classes: "",
            text: "",
            status: "",
        };

        field_name.forEach((field) => {
            if (field.status === "updated") {
                html.classes += `tw-text-green-500 tw-font-semibold`;
            }
            if (field.status === "deleted") {
                // html +=
                html.classes += `tw-text-red-500 tw-line-through tw-font-semibold`;
            }
            result = field.changed_value;
            html.status = field.status;
            html.text = result;
        });
        html.text = html.text ? html.text : og_value;
        return noHtml ? result : html;
    }
}

// Download file function
export function downloadFile(fileUrl) {
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    // fileLink.setAttribute('download', fileUrl
    //     .substring(fileUrl.lastIndexOf('/') + 1));
    fileLink.setAttribute("download", "");
    fileLink.click();
}

export function throttle(func, delay) {
    if (typeof func !== "function") throw new TypeError("1st param must be a function");
    if (!Number.isInteger(delay)) throw TypeError("2nd param must be integer");

    let time = -Infinity;

    return (...args) => {
        const isDelayLeft = time + delay < Date.now();
        time = Date.now();

        return isDelayLeft ? func(...args) : new Function();
    };
}

export function debounce(func, timeoutMs) {
    if (typeof func !== "function") throw new TypeError("1st param must be a function");
    if (!Number.isInteger(timeoutMs)) throw new TypeError("2nd param must be integer");

    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), timeoutMs);
    };
}
export function omit(object, excludeKey) {
    if (typeof object !== "object" && Array.isArray(object)) throw new TypeError("object must be an object");
    if (typeof excludeKey !== "string") throw new TypeError("excludeKey must be a string");

    const res = {};
    for (const key in object) {
        const isNotKeyExcluded = key !== excludeKey;

        if (isNotKeyExcluded) res[key] = object[key];
    }

    return res;
}
export function formatDateToUTC(dt, format = "d MMMM yyyy") {
    return DateTime.fromISO(dt)
        .toUTC()
        .toFormat(format);
}
export const isPrimitive = (val) => !(val instanceof Object);

export async function splitCountryCode(mobileNumber) {
    let countryCodesData = await services.getAllCountryCodes();
    const countryCodes = countryCodesData.map((data) => "+" + data.country_phone_code);
    mobileNumber = mobileNumber.replace(/[^0-9+]/g, "");
    let response = {};
    countryCodes.forEach((res) => {
        if (mobileNumber.includes(res)) {
            response.code = mobileNumber.substring(0, res.length);
            response.number = mobileNumber.substring(res.length);
            const id = countryCodesData.filter((res) => "+" + res.country_phone_code == response.code);
            response.id = id[0]._id;
        }
    });
    return response;
}
